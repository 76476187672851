import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { device } from '../device';

export const Container = styled.section`
    width: 100%;
    padding: 4rem 13.5%;
    background: #F4F5F7;

    @media ${device.mobile} {
        width: 112%;
        padding: 4rem 8%;
    }
`;

export const ReactMarkdownStyled = styled(ReactMarkdown)`
    h2 {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 30px;
    }

    p {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        margin: 1.875rem 0;
    }

    ul li {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        margin: 1rem 0;
    }

    ul li::marker {
        color: #45A7DF;
    }
`;