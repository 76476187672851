import React from 'react';

import * as S from './style.js';

const OurProtocolsImages = ({markdown, fragments, className = ""}) => {
    return (
        <S.Container className={className}>
            <h2>{fragments?.length > 0 && fragments[0]?.singletexts?.[0]}</h2>
            <S.Content>
                <S.ReactMarkdownStyled  children={markdown || '-'} />
            </S.Content>
        </S.Container>
    )
} 

export default OurProtocolsImages;