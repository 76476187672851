import React from 'react';

import * as S from './style.js'

const ContentYouWillFind = ({ markdown, className = "" }) => {

    return (
        <S.Container className={className}>
            <S.ReactMarkdownStyled children={markdown || '-'} />
        </S.Container>
    )
}

export default ContentYouWillFind;