// Libs
import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled, { css } from 'styled-components';
import ReactMarkdown from 'react-markdown';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import FeaturedMarketing, {
  BoxInformation,
  BoxContent,
  BoxImages,
} from 'components/featuredMarketing';
import { Container as ContainerBreadCrumb } from '../../components/breadCrumb';
import PromptServiceContent from 'components/promptService/promptServiceContent';
import Qa from 'components/Qa';
import ContentYouWillFind from 'components/contentYouWillFind';
import OurProtocolsImages from 'components/ourProtocolsImages';
import WhatPatientsSayAboutUs, {
  SayAbout,
} from 'components/home/whatPatientsSayAboutUs';
import Dropdown from 'components/dropdown';
import { ContentText } from 'components/dropdown/style.js';
import CardWorkWithUs from 'components/Card/cardWorkWithUs';
import CardGroupWorkWithUs from 'components/Card/cardGroupWorkWithUs';
import RecipeGuide from 'components/CentroOncologia/RecipeGuide';
import LMSContent from 'components/promptService/lmsContent';

// Image
import firstBlob from 'images/blobs/Slime_Purple.webp';
import secBlob from 'images/blobs/Slime_Pink.webp';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};

  margin-bottom: 2.5rem;

  @media ${device.laptop} {
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: -3.313rem;
    margin-bottom: -1.25rem;
  }
`;

const Part = styled.div`
  display: ${props => props.isDisplay};
  grid-column: ${props => props.gridColumn};
  border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  padding-top: ${props => props.paddingTop && '-10px'};
  padding-bottom: ${props =>
    props.paddingBottomDesk
      ? props.paddingBottomDesk
      : props.paddingBottom && '60px'};
  border-top: ${props => props.borderTop && '1px solid #C6C8CC'};
  margin-top: ${props => props.marginTop}
    ${props =>
      props.paddingDesktop &&
      css`
        padding: ${props.paddingDesktop};
      `}
    ${props =>
      props.bgColor &&
      css`
        background: ${props.bgColor};
      `}
    @media ${device.mobile} {
    padding-bottom: ${props => (props.investimento ? '0' : '1.25rem')};
    paddding-top: -8px;
  }

  :last-child {
    border-bottom: ${props => props.borderBottom && 'unset'};
  }

  section :first-child {
    padding: ${props =>
      props.notPadding
        ? '0px 0 0 !important'
        : props.notPaddingTop && '0 0 70px !important'};
    p {
      padding-left: ${props => props.notLeft && '0'};
    }

    @media ${device.laptop} {
      width: ${props => props.notPaddingTop && '100%'};
      padding: ${props =>
        props.notPadding
          ? '30px 0 0 !important'
          : props.notPaddingTop && '30px 0 !important'};
      border-bottom: ${props => props.notPaddingTop && 'unset'};
    }

    @media ${device.tablet} {
      padding: ${props => props.notPaddingTop && '0 0 30px !important'};
    }

    @media ${device.mobile} {
      margin: ${props => props.marginMob};
    }
  }

  section :last-child {
    padding: ${props => props.shortPadding && '20px 0 70px'};
    @media ${device.laptop} {
      padding: ${props => props.shortPadding && '0 0 30px'};
    }
  }

  section {
    div {
      div {
        h2 {
          width: ${props => props.largeTitle && '28rem'};

          @media ${device.laptop} {
            width: 100%;
          }
        }
      }
    }
  }

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIBelaVista ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
    padding-top: ${props => props.paddingTop && '30px'};
    padding-bottom: ${props => props.notPaddingTop && '30px'};
    border-bottom: ${props =>
      props.borderBottom || props.test
        ? 'unset'
        : props.notPaddingTop && '1px solid #C6C8CC'};
    border-top: ${props => props.paddingTop && '1px solid #C6C8CC'};

    section {
      div {
        display: ${props => props.paddingTop && 'flex'};
      }
    }

    ${props =>
      props.fullWidthMobile &&
      css`
        width: 100vw;
        margin: 0 -1.25rem;
      `}

    ${props =>
      props.paddingMobile &&
      css`
        padding: ${props.paddingMobile};
      `}

    ${props =>
      props.marginTopMobile &&
      css`
        margin-top: ${props.marginTopMobile};
      `}
  }

  @media ${device.tablet} {
    padding-top: ${props => props.paddingTop && '0'};
    border-top: ${props => props.paddingTop && 'unset'};
  }

  @media ${device.laptop} {
    border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
    :last-child {
      border-bottom: ${props => props.borderBottom && 'unset'};
    }
  }
`;

export const Title = styled.h2`
  margin-top: 2rem;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 1.5rem;

  @media ${device.laptop} {
    margin-top: 1.9375rem;
    margin-bottom: 1.125rem;
    font-size: 1.125rem;
  }
`;

export const AccordionContainer = styled.div`
  margin-bottom: 2rem;

  @media ${device.laptop} {
    margin-bottom: 1.875rem;
  }
`;

export const AccordionContainerTitle = styled(ReactMarkdown)`
  p,
  h2 {
    font-size: 2.25rem;
    font-weight: 600;
    text-align: center;
    margin: 4.375rem 0;

    @media ${device.laptop} {
      font-size: 1.625rem;
      margin: 1.875rem 0;
    }
  }
`;

const FeaturedMarketingnStyled = styled(FeaturedMarketing)`
  padding-bottom: 3.5rem;

  ${ContainerBreadCrumb} {
    z-index: 1;
  }

  ${BoxInformation} {
    height: 34.6rem;

    h1 {
      margin-top: 0rem;
      margin-bottom: 0rem;
    }

    p > a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18.75rem;
      font-size: 14px;
      color: rgb(255, 255, 255);
      background: rgb(69, 167, 223);
      border-radius: 100px;
      padding: 1rem 1.25rem;
      font-weight: normal;
      margin-top: 3rem;

      img {
        transform: none;
      }
    }

    p {
      width: 23.125rem;
      font-size: 14px;
      max-width: 100%;
      line-height: 1.625rem;

      img {
        padding-right: 0.8125rem;
        transform: translate(0px, 80%);
      }
    }

    @media ${device.laptop} {
      height: 21rem;
      margin-bottom: 1rem;
      padding-bottom: 1rem;

      p > a {
        margin-top: 1.25rem;
        width: 100%;
      }
    }

    @media ${device.tablet} {
      margin-top: 1rem;

      p {
        width: 100%;
      }
    }

    @media ${device.mobile} {
      margin-top: 0.5rem;

      h1 {
        margin-top: 2rem;
        width: 100%;
      }
    }
  }

  @media ${device.laptop} {
    padding-bottom: 1rem;
  }

  @media ${device.tablet} {
    padding-bottom: 1rem;
    width: 112%;

    ${BoxImages} {
      justify-content: center;
    }

    ${BoxInformation} {
      padding-right: 2.938rem;
      padding-left: 2.938rem;
    }
  }

  @media ${device.mobile} {
    padding-top: 0.1rem;
    padding-bottom: 0rem;
    width: 112%;

    ${BoxInformation} {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  ${BoxImages} {
    @media ${device.laptop} {
      .ImgMarketing {
        width: 30rem;
      }

      @media ${device.tablet} {
        margin-top: 5rem;

        .ImgMarketing {
          width: 45rem;
        }
      }

      @media ${device.mobile} {
        justify-content: center;
        margin-top: 1rem;

        .ImgMarketing {
          width: 23rem;
        }
      }

      @media ${device.mobile375} {
        .ImgMarketing {
          width: 21rem;
        }
      }

      @media ${device.mobile320} {
        .ImgMarketing {
          width: 17rem;
        }
      }
    }
  }
`;

const PromptServiceContentStyled = styled(PromptServiceContent)`
  ul li::marker {
    color: #000;
  }

  ul li {
    margin: 3.25rem 0;
  }

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 1.5rem;
  }

  @media ${device.tablet} {
    padding-bottom: 0rem !important;
  }

  @media ${device.mobile} {
    ul li {
      margin: 0 0 2.5rem 0;
    }
  }
`;

const GridWhoPatientsSayAboutUs = styled.div`
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: auto repeat(12, 70px) auto;
  -webkit-column-gap: 30px;
  column-gap: 30px;

  background-color: #f4f5f7;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
    margin: 0 -20px;
  }

  @media ${device.tablet} {
    margin: 0 -20px;
    margin-top: 0rem;
  }

  @media ${device.mobile} {
    display: flex;
    justify-self: center;
    height: auto;
  }

  @media ${device.mobile320} {
    padding-left: 0;
    margin: 0 -40px;
  }
`;

const WhatPatientsSayAboutUsStyled = styled(WhatPatientsSayAboutUs)`
  ${SayAbout} {
    p em {
      justify-content: center;
      gap: 2rem;
    }

    p strong {
      padding-top: 0;
    }

    @media ${device.mobile} {
      em {
        width: 100vw;
      }
      p strong {
        text-align: center;
      }
    }
  }
`;

const TeamContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 4.375rem;
  padding-bottom: 2.5rem;

  section {
    width: 50%;
    padding: 0;
    border-top: none;

    div > div > h2 {
      width: 26rem;
      line-height: 1.875rem;
    }
  }

  section:first-child {
    padding: 0 0 0 0 !important;
  }

  div {
    width: 50%;
  }

  div > div {
    width: 100%;
  }

  a {
    color: #45a7df;
  }

  a:visited {
    color: #45a7df;
  }

  @media ${device.mobile} {
    padding-bottom: 0;
    padding-top: 0;

    section {
      width: 100%;

      div > div > h2 {
        width: 13rem;
      }
    }

    div {
      width: 100%;
    }

    section > div:first-child {
      padding: 0;
    }

    section:first-child {
      padding: 2rem 0 0 0 !important;
    }

    section:last-child {
      padding: 0;
    }
  }
`;

const DropdownStyled = styled(Dropdown)`
  ${ContentText} {
    em {
      color: #8f9194;
    }
  }
`;

const CardGroupWorkWithUsStyled = styled(CardGroupWorkWithUs)`
  padding: 1.875rem 0 4.375rem 0;

  > div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1.875rem;
  }

  > h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 0;
  }

  @media ${device.mobile} {
    width: 100%;
    padding: 2.5rem 0 0 0;

    > h2 {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 1rem;
    }

    > div {
      width: 111%;
      display: flex;
      margin: 0 -1rem;
      padding: 0 1rem;
      flex-wrap: nowrap;
      overflow: scroll;
      gap: 0.625rem;

      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

const CardWorkWithUsStyled = styled(CardWorkWithUs)`
  width: 23.125rem;
  height: 17.125em;
  justify-content: flex-start;
  gap: 0;
  padding: 1.5rem;

  h2 {
    width: 15rem;
    word-spacing: 100vw;
    margin-bottom: 1.5rem;
  }

  a {
    color: #000;
  }

  a:visited {
    color: #000;
  }

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;

    a {
      color: #8f9194;
    }

    a:visited {
      color: #8f9194;
    }
  }

  ul {
    margin-top: 2px;
    margin-bottom: 0;
    color: #8f9194;
    a {
      color: #8f9194;
    }

    a:visited {
      color: #8f9194;
    }

    li {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 0;
    }
  }

  p:last-child {
    position: sticky;
    top: 100%;
  }

  @media ${device.mobile} {
    width: 16.875rem;
    height: 15.5rem;
    padding: 1.1rem 0.98rem 0.98rem 0.98rem;

    h2 {
      word-spacing: unset;
    }
  }
`;

const ContentYouWillFindStyled = styled(ContentYouWillFind)`
  div > ul {
    margin-left: 1.1rem;
  }

  @media ${device.laptop} {
    width: 102vw;
    margin-left: -2rem;
  }

  @media ${device.mobile} {
    padding: 1.875rem 8% 1rem 8%;

    > div > h2 {
      font-size: 18px;
    }

    > div > p {
      margin: 1.25rem 0;
    }
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop) => {
  const titleCard = getMarkdown(page, 'ckwnzn3ko6c9k0c25p60ym3pa')[0]
    .singletexts;
  const cardsMarkdown = getMarkdown(page, 'ckwnzn3ko6c9k0c25p60ym3pa', true);
  const splittedCardsMarkdown = cardsMarkdown.split('(end)\n\n').filter(e => e);

  return (
    <GridContainer isDesktop={isDesktop}>
      <Part gridColumn={'2 / -2'}>
        <FeaturedMarketingnStyled
          markdown={getMarkdown(page, 'ckwl0gkxsyywu0a75fftepys4', true)}
          breadCrumb={getMarkdown(page, 'ckwl0mu8wz2pe0b73e2x2gijd', true)}
          asset={getMarkdown(page, 'ckwl0gkxsyywu0a75fftepys4', false, true)}
          blobs={{ firstBlob, secBlob }}
          firstBlobTransformDesk="translate(189%,-38%) rotate(42.85deg)"
          firstBlobWidthDesk="13.063rem"
          firstBlobTransformDeskLarge="translate(171%,-38%) rotate(42.85deg)"
          firstBlobWidthDeskLarge="40%"
          firstBlobTransformLap="translate(235%,-31%) rotate(42.85deg)"
          firstBlobWidthLap="31%"
          firstBlobTransformMob="translate(215%, -38%) rotate(42.85deg)"
          firstBlobWidthDesk="13.063rem"
          firstBlobWidthMob="31%"
          secBlobTransformDesk="translate(-165%, 70%) rotate(-124.78deg)"
          secBlobTransformDeskLarge="translate(-165%, 70%) rotate(-124.78deg)"
          secBlobWidthDeskLarge="40%"
          secBlobTransformLap="translate(-229%,96%) rotate(-124.78deg)"
          secBlobWidthLap="31%"
          secBlobTransformMob="translate(-215%,152%) rotate(-124.78deg)"
          secBlobWidthMob="31%"
          titleWidthLap="30rem"
          boxInfoTextWidthDesk="28.125rem"
          boxContentMarginTopDesk="-1.438rem"
          boxContentMarginTopLap="1rem"
          boxContentPaddingBottomDesk="0"
          boxInfoParagraphImgWidth="unset"
          boxInfoParagraphImgHeight="unset"
          marginTopTitle="3.125rem"
          titleMarginLap="0"
          containerNoPaddingTopLap
          boxImagesImgOuterWrapperWidth="unset"
          marginTopBoxInfoMob="-7rem"
          marginTopBoxInfoTab="-9rem"
          positionLeftImageMarketingMobile320="0.9rem"
          positionLeftImageMarketingMobile="0rem"
          overflowContainerMobile="hidden"
          positionTopImageMarketingMobile="0.rem"
          widthImgMarketingMobile="10rem"
          widthImgMarketingTab="17rem"
          widthImgMarketingLap="25rem"
          marginBottomTitleDesk="5rem"
          boxContentFlexDirectionTab="column-reverse"
          boxContentFlexDirectionLap="unset"
          titleWidthMob="9.438rem"
          titleWidthMob320="7rem"
          titleFontSizeMob360="1.25rem"
          titleFontSizeMob320="1.125rem"
          boxInfoHeightLap="9rem"
          titleWidthDesk="32.5rem"
          widthImgMarketingDesk="35.56rem"
          heightImgMarketingDesk="28.68rem"
          ImgAdjustWidth="35.56rem !important"
          ImgAdjustHeight="27.68rem !important"
          widthImgMarketingMobile="11rem"
          justifyContentFeaturedMarketing="end"
          boxInfoHeightMobile="6.7rem"
          marginBreadCrumb="0 0 0"
          containerMarginBottomLap="1rem"
          boxInfoChild6PaddingTop="1.25rem"
          boxInfoChild5PaddingTop="2.5rem"
          boxInfoChild3PaddingTop="1.875rem !important"
          boxInfoChild3PaddingTopTab="0.875rem !important"
          boxImagesMarginTop="0.6rem"
          marginTopBoxInfoDesk="-0.6rem"
          colorStrongChild3
          boxInfoChild5PaddingTopTab="1rem"
          boxInfoChild6PaddingTopTab="0.8rem"
        />
      </Part>
      <Part gridColumn={'2 / -2'} largeTitle notLeft marginTop="1.875rem">
        <PromptServiceContentStyled
          markdown={getMarkdown(page, 'ckwl1w0sg016u0a75uwz51jr6', true)}
          fragments={getMarkdown(page, 'ckwl1w0sg016u0a75uwz51jr6')}
          isShowing
          withoutBorderTop
          containerWidth="50%"
          containerPaddingDesk="4.375rem 0 1.5rem 0"
          containerPaddingLaptop="0.125em 0 0.4rem 0"
          containerWidthLap="100%"
          notTitleBold
          isBottom
          prontoAtendimento
          isFacilities
          boxContentPaddingBottomLaptop="0.375rem"
          containerPaddingLap="2.375rem 0"
          notDuplicateUl="none"
        />
      </Part>
      <Part gridColumn={'1 / -1'} marginMob="-1.3rem">
        <ContentYouWillFindStyled
          markdown={getMarkdown(page, 'ckwl25a4g0a5e0a757u6jcg28', true)}
        />
      </Part>
      <Part gridColumn={'1 / -1'} marginMob="-1.3rem">
        <OurProtocolsImages
          markdown={getMarkdown(page, 'ckwnm7k60owiu0b28bv9ce05j', true)}
          fragments={getMarkdown(page, 'ckwnm7k60owiu0b28bv9ce05j')}
        />
      </Part>
      <GridWhoPatientsSayAboutUs>
        <Part gridColumn={'2 / -2'}>
          <WhatPatientsSayAboutUsStyled
            mainText
            contentPadding="0 20rem"
            markdown={getMarkdown(page, 'ckwnsprhsu3iz0c20b8eufv72', true)}
            fragments={getMarkdown(page, 'ckwnsprhsu3iz0c20b8eufv72', false)}
          />
        </Part>
      </GridWhoPatientsSayAboutUs>
      <Part gridColumn={'2 / -2'} borderBottom paddingTop={false}>
        <TeamContent>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckwnx1gfk44wy0b31h8q0urxt', true)}
            fragments={getMarkdown(page, 'ckwnx1gfk44wy0b31h8q0urxt')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isPatologica
            isFacilities
          />
          <DropdownStyled
            noTitle
            marginTopTitleLap="2.188rem"
            marginBottomTitleLap="2.188rem"
            markdown={getMarkdown(page, 'ckwnxa80o4e070b31s6ofpbky', true)}
            fragments={getMarkdown(page, 'ckwnxa80o4e070b31s6ofpbky')}
          />
        </TeamContent>
      </Part>
      <Part gridColumn={'2 / -2'} marginMob="-1.3rem" investimento>
        <CardGroupWorkWithUsStyled>
          <h2>{titleCard}</h2>
          <div>
            {splittedCardsMarkdown.map((card, index) => (
              <CardWorkWithUsStyled key={index} contentMarkdown={card} />
            ))}
          </div>
        </CardGroupWorkWithUsStyled>
      </Part>
      <Part
        gridColumn={'2 / -2'}
        paddingTop
        paddingBottomDesk="1.25rem"
        paddingBottomMob="2.5rem"
        largeTitle
        notLeft
      >
        <PromptServiceContent
          markdown={getMarkdown(page, 'ckwovynowd13g0c76hn41atox', true)}
          fragments={getMarkdown(page, 'ckwovynowd13g0c76hn41atox')}
          padding="0"
          marginListDesk="0rem"
          notTitleBold
          isBottom
          isFacilities
          titleUlDisplay="none"
          isOncologia
          isDoacao
          withoutBorderTop
          contentMarginBottom="0"
          contentTagUlListStyleType="none"
          contentTagLiImgMargin="0 0.625rem 0 0"
          contentUlListWithMultiLine
          isNutrologia
          contentPChild5InfoCard
          contentPChild6InfoCard
          contentPChild10InfoCard
          contentPChild11InfoCard
          contentTagEmColor="#000"
          boxContentPaddingBottomLaptop="0"
          titleWidth="18.75rem"
          prontoAtendimento
          diretrizesParaRetomada="0rem"
          marginListDesk="2rem 0"
          marginListLap="1rem 0 0.5rem 0"
        />
      </Part>
      {/* <Part
        gridColumn={'1 / -1'}
        fullWidthMobile
        paddingDesktop="4.375rem 0"
        paddingMobile="1.25rem 0"
        marginTopMobile="1.25rem"
        bgColor="linear-gradient(135deg, #1D52A3 0%, #45A7DF 100%);"
      >
        <RecipeGuide
          markdownContent={getMarkdown(page, 'ckwp186zsfcob0b26wznjog6z', true)}
          image={getMarkdown(page, 'ckwp186zsfcob0b26wznjog6z', false, true)}
          diretrizesParaRetomada
        ><LMSContent />
          </RecipeGuide>
      </Part> */}
      {page?.[0]?.qa && (
        <Part gridColumn={'2 / -2'}>
          <Qa
            data={page?.[0]?.qa}
            isFormatted={true}
            noBorderBottomLastElement
          />
        </Part>
      )}{' '}
    </GridContainer>
  );
};
const Consultoria = ({ page }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'ckwl0gkxsyywu0a75fftepys4', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />{' '}
      {renderPage(page, isDesktop)}{' '}
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckwkytlhcxzn60b22jml4b5d9" }) {
                fragments {
                  id
                  name
                  singletexts
                  markdown
                  localizations {
                    singletexts
                    markdown
                    datafragment
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                qa(locales: pt_BR) {
                  titulo
                  pergunta
                  autor
                  doctors {
                    active
                    assignment
                    name
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <Consultoria page={response.gcms.site.pages} />;
      }}
    />
  );
};
