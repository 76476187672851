import React, { useCallback, useState } from 'react';

import * as S from './style.js';

// Images
import ArrowUp from 'images/icons/arrow-up.svg';
import ArrowDown from 'images/icons/arrow-down-lightblue.svg';

const Dropdown = ({
  fragments,
  markdown,
  marginTopTitleLap,
  marginBottomTitleLap,
  noTitle,
  className = ""
}) => {
  const [collapseOpen, setCollapseOpen] = useState([]);
  const title = fragments && fragments[0].singletexts[0];

  const splittedMarkdown = markdown.split('(end)\n\n').filter(e => e);
  const markdownContent = splittedMarkdown.map(text => {
    return text.split('\n\n');
  });

  const renderDropdown = useCallback(
    (drop, indexList) => {
      let statusCollapse = collapseOpen.includes(indexList);

      return (
        <S.DropdownContainer>
          <S.DropdownBoxTitle isOpen={statusCollapse}>
            <S.DropdownTitle>{drop.name}</S.DropdownTitle>
            {statusCollapse ? (
              <img src={ArrowUp} alt={`Fechar ${drop.name}`} />
            ) : (
              <img src={ArrowDown} alt={`Visualizar ${drop.name}`} />
            )}{' '}
          </S.DropdownBoxTitle>
          {statusCollapse && (
            <S.ContentText escapeHtml={false} children={drop.text} />
          )}{' '}
        </S.DropdownContainer>
      );
    },
    [collapseOpen]
  );

  const handleListInformationPeoples = idCollapse => {
    let auxCollapse = collapseOpen.slice();

    const item = auxCollapse.includes(idCollapse);

    if (!item) {
      auxCollapse.push(idCollapse);
    } else {
      const removeCollapse = auxCollapse.indexOf(idCollapse);
      auxCollapse.splice(removeCollapse, 1);
    }

    setCollapseOpen(auxCollapse);
  };

  let drop = {
    name: '',
    text: '',
  };

  return (
    <div>
      {!noTitle && (
        <S.Title
          marginTopTitleLap={marginTopTitleLap}
          marginBottomTitleLap={marginBottomTitleLap}
        >
          {title}
        </S.Title>
      )}
      {markdownContent.map((item, indexList) => {
        drop.name = item[0].replace('###', '');
        drop.text = item.filter(item => !item.includes('###')).join(' ');

        return (
          <S.DropdownContent
            key={indexList}
            onClick={() => handleListInformationPeoples(indexList)}
          >
            {renderDropdown(drop, indexList)}{' '}
          </S.DropdownContent>
        );
      })}{' '}
    </div>
  );
};

export default Dropdown;
