import styled from 'styled-components';

import ReactMarkdown from 'react-markdown';
import {device} from '../device';

export const Container = styled.section`
    width: 100%;
    padding: 4rem 13.5%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    h2 {
        font-family: Inter;
        font-size: 36px;
        line-height: 42px;
        text-align: center;
        width: 62.125rem;
        margin-bottom: 4rem;

        @media ${device.mobile} {
            width: 22rem;
            font-size: 26px;
            margin-bottom: 2rem;
            line-height: 30px;
        }
    }

    @media ${device.laptop} {
        padding: 4rem 0;
        margin: 0;
        width: 100%;
    }

    @media ${device.tablet} {
        padding: 4rem 0;
        margin: 0;
        width: 100vw;

        h2 {
            width: 20rem;
        }
    }

    @media ${device.mobile} {
        padding: 4rem 0% 3rem 0;
    }
`;

export const Content = styled.div`
    @media ${device.mobile} {
        width: 26rem;
        overflow: scroll; 
    }

    ::-webkit-scrollbar {
        display: none;
    }

    @media ${device.mobile375} {
        width: 24rem;
    }
`;

export const ReactMarkdownStyled = styled(ReactMarkdown)`
    display: flex;
    gap: 1.875rem;
    justify-content: center;

    @media ${device.mobile} {
        width: 60rem;
        margin: 0 0rem 0 -2rem;

        img {
            width: 16.875rem;
        }
    }
`;