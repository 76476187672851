// Libs
import React, { useState } from 'react';
import styled from 'styled-components';
import { device } from '../device';
import * as Yup from 'yup';

// Components
import InputComponent from 'components/Input';
import ButtonComponent from 'components/SimpleButton';
import { Grid } from '@material-ui/core';

// Services
import { userSend } from 'components/services/salesForceAPI.js';

const Content = styled.div`
  width: 100%;
`;

const ContentLogin = styled.div`
  display: ${props => (props.isVisible ? 'block' : 'none')};
  width: 100%;
  border-radius: 0.5rem;
  background: #ffffff;
  padding: 2.5rem 1.875rem;
  margin-bottom: 3.125rem;

  @media ${device.laptop} {
    margin-bottom: 1.25rem;
  }
`;

const BoxBottom = styled.div`
  display: ${props => (props.isVisible ? 'block' : 'none')};
`;
const BoxDownloadButtons = styled.div`
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-between;
  gap: 1.875rem;

  @media ${device.tablet} {
    flex-direction: column;
    gap: 1.25rem;
  }
`;

const Text = styled.p`
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${props => props.color || '#ffffff' };
`;

const LMSContent = () => {
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isVisibleDownloadLinks, setIsVisibleDownloadLinks] = useState(false);

  const phoneRegExp = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/;
  let schema = Yup.object().shape({
    name: Yup.string().required('Nome obrigatório').min(2, 'Nome obrigatório'),
    lastname: Yup.string()
      .required('Sobrenome obrigatório')
      .min(2, 'Sobrenome obrigatório'),
    email: Yup.string()
      .email('Digite um e-mail valido')
      .required('E-mail obrigatório'),
    phone: Yup.string()
      .matches(phoneRegExp, 'Digite um telefone valido')
      .min(12, 'Digite um telefone valido'),
  });

  const handleSubmit = async () => {
    setLoading(true);
    setErrors({});

    try {
      await schema.validate(
        {
          name,
          lastname,
          email,
          phone,
        },
        {
          abortEarly: false,
        }
      );

      await userSend({ name, lastname, email, phone });

      setIsVisibleDownloadLinks(true);
      setLoading(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        setErrors(errorMessages);
      }

      setLoading(false);
      return;
    }
  };

  return (
    <Content>
      <ContentLogin isVisible={!isVisibleDownloadLinks}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <InputComponent
              onChange={val => setName(val)}
              error={errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputComponent
              onChange={val => setEmail(val)}
              label="E-mail"
              placeholder="Digite o seu e-mail"
              error={errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputComponent
              onChange={val => setPhone(val)}
              label="Celular"
              placeholder="(__) _____-____"
              mask={
                phone.length <= 10
                  ? '+99 (99) 9999-9999?'
                  : '+99 (99) 99999-9999'
              }
              error={errors.phone}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputComponent
              onChange={val => setEmail(val)}
              label="Empresa"
              placeholder="Digite o nome da empresa"
              error={errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputComponent
              onChange={val => setEmail(val)}
              label="Cargo"
              placeholder="Digite o seu cargo"
              error={errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
              <ButtonComponent
                width="unset"
                heightDesktop="unset"
                heightMobile="unset"
                color="#FFF"
                background="#45A7DF"
                marginTop="0"
                paddingDesktop="0.875rem 1.25rem"
                paddingMobile="1.25rem 1.75rem"
                label="Baixar o conteúdo"
                onClick={handleSubmit}
                loading={loading}
                isButtonActive={true}
                hasHover={false}
                loaderSize={14}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Text color="#8F9194">
              Prometemos não utilizar suas informações de contato para enviar qualquer tipo de SPAM.
           </Text>
          </Grid>
        </Grid>
      </ContentLogin>
    </Content>
  );
};

export default LMSContent;